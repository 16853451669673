.App-logo {
  margin-top: 200px;
  width: 95%;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.component-parent {
  color: black;
  width: 90%;
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 160px;
  font-size: 18px;
}

.home-body {
  margin-left: auto;
  margin-right: auto;
}

.subheading {
  font-weight: 600;
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
}

.twitter {
  margin-top: 27px;
  font-size: 18px;
}
.twitter-link:link {
  color: blue;
  font-weight: 500;
}
.twitter-link:visited {
  color: blue;
}
/* mouse over link */
.twitter-link:hover {
  color: white;
  background-color: blue;
}
/* selected link */
.twitter-link:active {
  color: rgb(217, 217, 255);
}

.projects-header {
  font-size: 32px;
  font-weight: 800;
  text-align: left;
  margin-top: 105px;
}

.projects-body {
  margin-left: 8px;
  margin-right: 8px;
}
.channels-logo {
  width: 60%;
  margin-top: 45px;
}
.project-logo {
  width: 60%;
  margin-top: 67px;
}
.project-logo-friend {
  margin-left: -20px;
  width: 60%;
  margin-top: 67px;
  margin-bottom: -10px;
}
.project-logo.press {
  margin-left: -10px;
}
.project-header {
  font-weight: 600;
  margin-top: 14px;
}

.project-links {
  font-weight: 400;
  margin-top: 25px;
}

a:link {
  color: blue;
  font-weight: 600;
}

/* visited link */
a:visited {
  color: blue;
}

/* mouse over link */
a:hover {
  color: white;
  background-color: blue;
}
/* selected link */
a:active {
  color: rgb(217, 217, 255);
}

.project-header-no-logo {
  margin-top: 84px;
  font-weight: 600;
}
